<template>
  <b-modal
    id="modal-role"
    ref="refModal"
    title="Vai trò"
    size="lg"
    :ok-title="isSubmitting ? 'Đang lưu' : 'Lưu'"
    :ok-disabled="isSubmitting"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    scrollable
    @show="onOpenModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <div
      v-if="isLoading"
      class="d-flex justify-content-center mb-1"
    >
      <b-spinner

        class="text-center"
        variant="secondary"
      />
    </div>
    <!-- Body -->
    <validation-observer
      v-else
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group
                label="Mã vai trò"
              >
                <template
                  v-slot:label
                >
                  Mã vai trò
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <b-form-input
                  id="name"
                  v-model="itemLocal.name"
                  placeholder="Mã vai trò"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">
                  {{
                    validationContext.errors[0]
                  }}
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="display_name"
              rules="required"
            >
              <b-form-group
                label="Tên vai trò"
              >
                <template
                  v-slot:label
                >
                  Tên vai trò
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <b-form-input
                  id="display_name"
                  v-model="itemLocal.display_name"
                  placeholder="Tên vai trò"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">
                  {{
                    validationContext.errors[0]
                  }}
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Mô tả"
            >
              <b-form-textarea
                id="textarea-default"
                v-model="itemLocal.description"
                placeholder="Tài khoản dành cho các bạn nhân viên phụ trách quản lý cơ sở y tế."
                rows="2"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-checkbox
          v-model="allChecked"
        >
          Tất cả
        </b-form-checkbox>

        <!-- PERMISSION TABLE -->

        <b-row class="mt-1">
          <b-col cols="12">
            <!-- <abilities-table
              v-model="itemLocal.abilities"
              :default-value="itemLocal.permissions"
            /> -->
            <permission-table
              v-model="itemLocal.permissionObjects"
              :default-value="itemLocal.permissionObjects"
            />
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BFormCheckbox,
  BSpinner,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import useRoleModal from './useRoleModal';
import PermissionTable from './PermissionTable.vue';

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    PermissionTable,
    BFormCheckbox,
    BSpinner,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },

  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      isLoading,
      refModal,
      itemLocal,
      isSubmitting,
      allChecked,
      resetItemLocal,
      resetModal,
      onSubmit,
      onOpenModal,
    } = useRoleModal(props, emit, refFormObserver);

    return {
      isLoading,
      refModal,
      itemLocal,
      isSubmitting,
      allChecked,
      resetItemLocal,
      resetModal,
      onSubmit,
      onOpenModal,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
