<template>
  <vue-good-table
    :columns="columns"
    :rows="data"
    :select-options="{
      enabled: false,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }"
  >
    <template slot="loadingContent">
      <b-spinner
        label="Loading"
        type="grow"
      />
    </template>
    <template
      slot="table-row"
      slot-scope="props"
    >
      <!-- Column: Code -->
      <span v-if="props.column.field === 'isChecked'">
        <b-form-checkbox
          :checked="props.row.isChecked"
          @change="changeCell(props.row.originalIndex)"
        />
      </span>

      <!-- Column: Common -->
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>
    <div slot="emptystate">
      <div class="text-center text-muted">
        Không có bản ghi nào!
      </div>
    </div>
  </vue-good-table>
</template>

<script>
import {
  BFormCheckbox,
  BSpinner,
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';

export default {
  components: {
    BFormCheckbox,
    BSpinner,

    VueGoodTable,
  },
  props: {
    defaultValue: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      data: this.defaultValue,
    };
  },

  methods: {
    changeCell(index) {
      this.data[index].isChecked = !this.data[index].isChecked;
      this.$emit('input', this.data);
    },
  },
  setup() {
    // Table Handlers
    const columns = [
      {
        label: 'Mã',
        field: 'name',
        sortable: false,
      },
      {
        label: 'Tên quyền',
        field: 'display_name',
        sortable: false,
      },
      {
        label: 'Chọn',
        field: 'isChecked',
        sortable: false,
        width: '100px',
        thClass: 'text-center',
        tdClass: 'text-center',
      },

    ];

    return {
      columns,
    };
  },
};
</script>
